@import url('https://fonts.googleapis.com/css2?family=Jost:wght@400;500&display=swap');
@import '@radix-ui/colors/indigo.css';
@import '@radix-ui/colors/red.css';
@import '@radix-ui/colors/green.css';
@import '@radix-ui/colors/orange.css';
@import '@radix-ui/colors/slate.css';

/* Undersranding the scale */
/* https://www.radix-ui.com/colors/docs/palette-composition/understanding-the-scale */

:root {
  --primary-bg: var(--indigo-1);
  --primary-bg-subtle: var(--indigo-2);
  --primary-light: var(--indigo-3);
  --primary-light-hover: var(--indigo-4);
  --primary-light-focus: var(--indigo-5);
  --primary-border-light: var(--indigo-6);
  --primary-border: var(--indigo-7);
  --primary-border-focus: var(--indigo-8);
  --primary: var(--indigo-9);
  --primary-hover: var(--indigo-10);
  --primary-dark: var(--indigo-11);

  --error-bg: var(--red-1);
  --error-bg-subtle: var(--red-2);
  --error-light: var(--red-3);
  --error-light-hover: var(--red-4);
  --error-light-focus: var(--red-5);
  --error-border-light: var(--red-6);
  --error-border: var(--red-7);
  --error-border-focus: var(--red-8);
  --error: var(--red-9);
  --error-hover: var(--red-10);
  --error-dark: var(--red-11);

  --success-bg: var(--green-1);
  --success-bg-subtle: var(--green-2);
  --success-light: var(--green-3);
  --success-light-hover: var(--green-4);
  --success-light-focus: var(--green-5);
  --success-border-light: var(--green-6);
  --success-border: var(--green-7);
  --success-border-focus: var(--green-8);
  --success: var(--green-9);
  --success-hover: var(--green-10);
  --success-dark: var(--green-11);

  --warning-bg: var(--orange-1);
  --warning-bg-subtle: var(--orange-2);
  --warning-light: var(--orange-3);
  --warning-light-hover: var(--orange-4);
  --warning-light-focus: var(--orange-5);
  --warning-border-light: var(--orange-6);
  --warning-border: var(--orange-7);
  --warning-border-focus: var(--orange-8);
  --warning: var(--orange-9);
  --warning-hover: var(--orange-10);
  --warning-dark: var(--orange-11);

  --neutral-bg: var(--slate-1);
  --neutral-bg-subtle: var(--slate-2);
  --neutral-light: var(--slate-3);
  --neutral-light-hover: var(--slate-4);
  --neutral-light-focus: var(--slate-5);
  --neutral-border-light: var(--slate-6);
  --neutral-border: var(--slate-7);
  --neutral-border-focus: var(--slate-8);
  --neutral: var(--slate-9);
  --neutral-hover: var(--slate-10);

  --text: var(--slate-12);
  --text-subtle: var(--slate-11);

  --fz-xs: 0.75rem;
  --fz-sm: 0.875rem;
  --fz-md: 1rem;
  --fz-lg: 1.25rem;
  --fz-xl: 1.5rem;

  --space-xxs: 0.25rem;
  --space-xs: 0.5rem;
  --space-sm: 0.75rem;
  --space-md: 1rem;
  --space-lg: 1.5rem;
  --space-xl: 2rem;

  --radii-xs: 2px;
  --radii-sm: 4px;
  --radii-md: 8px;
  --radii-lg: 16px;
  --radii-round: 50%;

  --transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1);

  --jost: 'Jost', Arial, Helvetica, sans-serif;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: var(--jost);
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.15px;
  color: var(--text);
  background-color: var(--neutral-bg-subtle);
}

a {
  color: inherit;
  text-decoration: none;
}

*,
*:after,
*:before {
  box-sizing: border-box;
}

img {
  max-width: 100%;
  height: auto;
}

b,
strong {
  font-weight: 500;
}

#modal-root {
  position: relative;
  z-index: 1400;
}
